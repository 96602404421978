import api from '@/services/api';
import {
  TCommissionProviderRuleReq,
  TProviderCommissionRuleApiResponse, TProviderCommissionRuleConditionPayload,
  TProviderCommissionRulePayload,
} from '@/services/requests/contracts/Commissions.types';
import { IApiResponse } from '@/types/interfaces';

export const ProviderCommissionRequests = {
  getCommissionProviderRules(
    contractId: string,
    profileId: string,
    data: TCommissionProviderRuleReq,
  ): Promise<TProviderCommissionRuleApiResponse> {
    return api.post(`/contracts/${contractId}/gate-commission-profiles/${profileId}/commission-provider-rule/view`, data);
  },

  createProviderCommissionRule(
    contractId: string,
    profileId: string,
    data: TProviderCommissionRulePayload,
  ): Promise<IApiResponse<any>> {
    return api.post(`/contracts/${contractId}/gate-commission-profiles/${profileId}/commission-provider-rule`, data);
  },

  updateProviderCommissionRule(
    contractId: string,
    profileId: string,
    ruleId: string,
    data: TProviderCommissionRulePayload,
  ): Promise<IApiResponse<any>> {
    return api.patch(`/contracts/${contractId}/gate-commission-profiles/${profileId}/commission-provider-rule/${ruleId}`, data);
  },

  deleteProviderCommissionRule(
    contractId: string,
    profileId: string,
    ruleId: string,
  ): Promise<IApiResponse<any>> {
    return api.delete(`/contracts/${contractId}/gate-commission-profiles/${profileId}/commission-provider-rule/${ruleId}`);
  },

  createProviderCommissionCondition(
    contractId: string,
    profileId: string,
    ruleId: string,
    data: TProviderCommissionRuleConditionPayload,
  ): Promise<IApiResponse<any>> {
    return api.post(`/contracts/${contractId}/gate-commission-profiles/${profileId}/commission-provider-rule/${ruleId}/condition`, data);
  },

  updateProviderCommissionCondition(contractId: string,
    profileId: string,
    ruleId: string,
    conditionId: string,
    data: TProviderCommissionRuleConditionPayload) {
    return api.put(
      `/contracts/${contractId}/gate-commission-profiles/${profileId}/commission-provider-rule/${ruleId}/condition/${conditionId}`,
      data,
    );
  },

  deleteProviderCommissionCondition(
    contractId: string,
    profileId: string,
    ruleId: string,
    conditionId: string,
  ) {
    return api.delete(`/contracts/${contractId}/gate-commission-profiles/${profileId}/commission-provider-rule/${ruleId}/condition/${conditionId}`);
  },
};
