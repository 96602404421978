import { AsyncComponent } from 'vue';

import { ROLES } from '@/constants';
import { IRouteConfig } from '@/types/interfaces';
import { generateNestedRoutes } from '@/utils';

const ContractPage: AsyncComponent = () => import(/* webpackChunkName: 'contracts' */
  '@/modules/contracts-old/pages/index.vue'
);

const ContractMerchantPage: AsyncComponent = () => import(/* webpackChunkName: 'contracts-merchant' */
  '@/modules/contracts-old/pages/merchant/index.vue'
);

const ContractIndividualPage: AsyncComponent = () => import(/* webpackChunkName: 'contracts-individual' */
  '@/modules/contracts-old/pages/individual/index.vue'
);

const ContractProviderCommissionPage: AsyncComponent = () => import(/* webpackChunkName: 'contracts-provider-commission' */
  '@/modules/contracts-old/pages/provider-commission/index.vue'
);

const ContractSystemCommissionPage: AsyncComponent = () => import(/* webpackChunkName: 'contracts-system-commission' */
  '@/modules/contracts-old/pages/system-commission/index.vue'
);

const ContractSystemDetailsRules: AsyncComponent = () => import(/* webpackChunkName: 'contract-system-details-rules' */
  '@/modules/contracts-old/pages/details/system-details-rules.vue'
);

const ProviderSystemDetailsRules: AsyncComponent = () => import(/* webpackChunkName: 'contract-provider-details-rules' */
  '@/modules/contracts-old/pages/details/provider-details-rules.vue'
);

const ContractSystemDetailsLimits: AsyncComponent = () => import(/* webpackChunkName: 'contract-system-details-limits' */
  '@/modules/contracts-old/pages/details/system-details-limits.vue'
);
const ContractProductDetailsRules: AsyncComponent = () => import(/* webpackChunkName: 'contract-product-details-rules' */
  '@/modules/contracts-old/pages/details/product-details-rules.vue'
);

const ContractProductDetailsLimits: AsyncComponent = () => import(/* webpackChunkName: 'contract-product-details-limits' */
  '@/modules/contracts-old/pages/details/product-details-limits.vue'
);

const ProviderSystemDetailsLimits: AsyncComponent = () => import(/* webpackChunkName: 'contract-provider-details-limits' */
  '@/modules/contracts-old/pages/details/provider-details-limits.vue'
);

const ContractProductCommissionPage: AsyncComponent = () => import(
  /* webpackChunkName: 'contract-product-commission' */
  '@/modules/contracts-old/pages/product-commission/index.vue'
);

export const SYSTEM_DETAILS_CHILDREN: IRouteConfig[] = [
  {
    path: ':profileId/rules',
    name: 'system-details-view',
    component: ContractSystemDetailsRules,
    meta: {
      translation: 'pages.contracts.navigation.commissions',
      level: 3,
    },
  },
  {
    path: ':profileId/limits',
    name: 'system-details-limits-view',
    component: ContractSystemDetailsLimits,
    meta: {
      translation: 'pages.contracts.navigation.limits',
      level: 3,
    },
  },
];

export const PRODUCT_COMMISSION_DETAILS_CHILDREN: IRouteConfig[] = [
  {
    path: ':profileId/rules',
    name: 'product-details-view',
    component: ContractProductDetailsRules,
    meta: {
      translation: 'pages.contracts.navigation.commissions',
      level: 3,
    },
  },
  {
    path: ':profileId/limits',
    name: 'product-details-limits-view',
    component: ContractProductDetailsLimits,
    meta: {
      translation: 'pages.contracts.navigation.limits',
      level: 3,
    },
  },
];

export const PROVIDER_COMMISSION_DETAILS_CHILDREN: IRouteConfig[] = [
  {
    path: ':profileId/rules',
    name: 'provider-details-view',
    component: ProviderSystemDetailsRules,
    meta: {
      translation: 'pages.contracts.navigation.commissions',
      level: 3,
    },
  },
  {
    path: ':profileId/limits',
    name: 'provider-details-limits-view',
    component: ProviderSystemDetailsLimits,
    meta: {
      translation: 'pages.contracts.navigation.limits',
      level: 3,
    },
  },
];

export const CONTRACT_CHILDREN: IRouteConfig[] = [
  {
    path: ':contractId/provider-commission',
    name: 'provider-commission',
    component: ContractProviderCommissionPage,
    meta: {
      level: 2,
      translation: 'pages.contracts.navigation.provider_commission',
    },
  },
  {
    path: ':contractId/product-commission',
    name: 'product-commission',
    component: ContractProductCommissionPage,
    meta: {
      level: 2,
      translation: 'pages.contracts.navigation.product_commission',
    },
  },
  {
    path: ':contractId/system-commission',
    name: 'system-commission',
    component: ContractSystemCommissionPage,
    meta: {
      level: 2,
      translation: 'pages.contracts.navigation.system_commission',
    },
  },
  ...generateNestedRoutes(
    'provider-commission',
    ':contractId/provider-commission',
    PROVIDER_COMMISSION_DETAILS_CHILDREN,
    { parentRoutePrefix: 'provider-commission' },
  ),
  ...generateNestedRoutes(
    'system-commission',
    ':contractId/system-commission',
    SYSTEM_DETAILS_CHILDREN,
    { parentRoutePrefix: 'system-commission' },
  ),
  ...generateNestedRoutes(
    'product-commission',
    ':contractId/product-commission',
    PRODUCT_COMMISSION_DETAILS_CHILDREN,
    { parentRoutePrefix: 'product-commission' },
  ),
];

export const CONTRACTS_CHILDREN: IRouteConfig[] = [
  {
    path: 'merchant',
    name: 'merchant',
    component: ContractMerchantPage,
    meta: {
      level: 1,
      translation: 'pages.contracts.navigation.merchant',
    },
  },
  {
    path: 'individual',
    name: 'individual',
    component: ContractIndividualPage,
    meta: {
      level: 1,
      translation: 'pages.contracts.navigation.individual',
    },
  },
  ...generateNestedRoutes(
    'merchant',
    'merchant',
    CONTRACT_CHILDREN,
    {
      permission: [ROLES.administrator, ROLES.cfo],
    },
  ),
  ...generateNestedRoutes(
    'individual',
    'individual',
    CONTRACT_CHILDREN,
    { permission: [ROLES.administrator, ROLES.cfo] },
  ),

];

export const CONTRACTS_OLD: IRouteConfig = {
  path: '/dashboard/contracts-old',
  component: ContractPage,
  meta: {
    permission: [ROLES.administrator, ROLES.compliance_manager, ROLES.cfo],
    translation: 'navigation.link.contracts',
    root: true,
    icon: 'icon-contracts',
  },
  redirect: CONTRACTS_CHILDREN[0],
  children: CONTRACTS_CHILDREN,
};
