import axios, {
  AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse,
} from 'axios';
import { getModule } from 'vuex-module-decorators';

import { ApiVersion } from '@/constants';
import { LocalStorageService } from '@/services/LocalStorageService';
import store from '@/store';
import { UserData } from '@/store/modules';

import ApiMethods from './ApiMethods';
import ApiMethodsSpecificVersion from './ApiMethodsSpecificVersion';
import { TAxiosMethods } from './types';

interface IApiConfig {
  api: TAxiosMethods & Pick<AxiosInstance, 'defaults'> & Record<ApiVersion, TAxiosMethods>;
  apiConfig: AxiosRequestConfig;
}

export default class ApiConfig implements IApiConfig {

  private readonly appBaseUrl: string

  private readonly apiVersions: readonly ApiVersion[]

  private readonly defaultVersion: ApiVersion

  private readonly axiosInstance: AxiosInstance

  private readonly apiInternal: TAxiosMethods & Pick<AxiosInstance, 'defaults'> & Partial<Record<ApiVersion, TAxiosMethods>>

  readonly apiConfig: AxiosRequestConfig

  constructor(options: {
    apiVersions: readonly ApiVersion[];
    currentApiVersion: ApiVersion;
    baseUrl: string;
  }) {
    const { apiVersions, currentApiVersion, baseUrl } = options;
    this.appBaseUrl = baseUrl;
    this.apiVersions = apiVersions;
    this.defaultVersion = currentApiVersion;
    this.apiConfig = {
      baseURL: this.getBaseUrl(),
    };
    this.axiosInstance = axios.create(this.apiConfig);
    const commonAxiosMethods = new ApiMethods(this.axiosInstance);
    this.apiInternal = {
      ...commonAxiosMethods,
      defaults: this.axiosInstance.defaults,
    };

    this.setupApi();
    this.setupInterceptors();
  }

  public get api() {
    return this.apiInternal as IApiConfig['api'];
  }

  public getAuthHeader(): {} {
    const token = LocalStorageService.get('token');

    return token ? { Authorization: `TOKEN ${token}` } : {};
  }

  private getBaseUrl(): string {
    return `${this.appBaseUrl}api/${this.defaultVersion}`;
  }

  private setupApi() {
    this.apiVersions.forEach((version) => {
      this.apiInternal[version] = new ApiMethodsSpecificVersion(
        {
          axiosInstance: this.axiosInstance,
          requestBaseUrl: this.getBaseUrl.call({ appBaseUrl: this.appBaseUrl, defaultVersion: version }),
        },
      );
    });
  }

  private setupRequestInterceptor() {
    this.axiosInstance.interceptors.request.use(
      (options) => {
        if (!options?.headers?.Authorization) {
          options.headers = {
            ...options.headers,
            ...this.getAuthHeader(),
          };
        }

        return options;
      },
      (error) => Promise.reject(error),
    );
  }

  private setupResponseInterceptor() {
    let userDataModule: any;
    this.axiosInstance.interceptors.response.use(
      (response): Promise<AxiosResponse> => Promise.resolve({
        ...response,
        originalResponse: response,
        error: null,
        response: response.data,
      }),
      async (error): Promise<AxiosError> => {
        const { status, config } = error.response;

        if (!userDataModule) {
          userDataModule = getModule(UserData, store);
        }

        if (status === 401 && config.url !== '/authorization') {
          await userDataModule.logout();
        }

        return Promise.resolve({
          ...error,
          originalResponse: error,
          response: null,
          error: error?.response?.data || true,
        });
      },
    );
  }

  private setupInterceptors() {
    this.setupRequestInterceptor();
    this.setupResponseInterceptor();
  }

}
