import { availableApiVersions, defaultApiVersion } from '@/constants';
import ApiConfig from '@/services/api/ApiConfig';

declare module 'axios' {
  interface AxiosResponse<T = any> {
    response: T;
    error: any;
  }

  interface AxiosError {
    error: any;
  }
}

const { BASE_URL } = process.env;

export const { api, apiConfig, getAuthHeader } = new ApiConfig({
  apiVersions: availableApiVersions,
  currentApiVersion: defaultApiVersion,
  baseUrl: BASE_URL,
});
export default api;
