import { LocaleMessages } from 'vue-i18n';

const en: LocaleMessages = {
  placeholder: {
    input: {
      percent: 'Input percent',
      fixed: 'Input fixed',
      amount_to: 'Input amount to',
      amount_from: 'Input amount from',
      tax_id: 'Tax ID number',
      account_name: 'Account name',
      currency: 'Currency',
      start_typing: 'Start typing',
      specify_amount: 'Specify amount',
      template_name: 'Input template name',
      amount: 'Input amount',
      product_price: 'Input product price',
      count: 'Input count',
      product_code: 'Input product code',
      payer_contact: 'Input payer contact',
      invoice_name: 'Input invoice name',
      description: 'Description',
      login: 'Email or phone number',
      input_login: 'Input email or phone number',
      input_iban: 'Input IBAN',
      input_recipient_account_number: 'Input recipient account number',
      specify_bank_name: 'Specify bank name',
      specify_bank_address: 'Specify bank address',
      input_bic: 'Input bic',
      input_swift: 'Input SWIFT',
      input_name_or_receiver: 'Input full name of the receiver',
      password: 'Password',
      input_name: 'Input name',
      input_users_contact: 'Input user\'s contact',
      input_middle_name: 'Input middle name',
      input_surname: 'Input surname',
      input_description: 'Input description',
      input_sn_prefix: 'Input SN prefix',
      input_order_number: 'Input order number',
      input_order_quote: 'Input order quote',
      active: 'Active',
      input_serial_number: 'Input serial number',
      input_current_password: 'Input current password',
      input_new_password: 'Input new password',
      input_confirm_password: 'Confirm new password',
      input_request_id: 'Input request identifier',
      input_email_or_phone: 'Input email or phone',
      search: 'Search',
      ru: 'Russian',
      uk: 'Ukrainian',
      en: 'English',
      date_from: 'Date from',
      date_to: 'Date to',
      input_organization_id: 'Input organization ID',
      input_city: 'Input city',
      input_street: 'Input street',
      input_house_number: 'Input house number',
      input_zip_code: 'Input zip code',
      input_email: 'Input email',
      input_phone_number: 'Input phone number',
      input_vat_value: 'Input VAT  e.g. BE3320442345, ATU99999999',
      input_last_name: 'Input last name',
      input_first_name: 'Input first name',
      input_company_name: 'Input company name',
      input_business_type: 'Input business type',
      input_value: 'Input value',
      input_contract_name: 'Input the contract name',
      website: 'Specify website URL',
      server_url: 'Specify server URL',
      result_url: 'Specify result URL',
      input_template_name: 'Input template name',
      input_wallet_serial: 'Input wallet serial',
      input_amount: 'Input amount',
      add_transfer_details: 'Add transfer details',
      input_full_name_of_receiver: 'Input full name of receiver',
      input_account_number_of_receiver: 'Input account number of receiver',
      input_bank_name: 'Input bank name',
      input_bank_address: 'Input bank address',
      input_name_of_receiver: 'Input name of receiver',
      input_full_name: 'Input full name',
      input_field: 'Input field',
      input_currency_name: 'Input currency name',
      input_currency_code: 'Input currency code',
      input_digital_code_currency: 'Input digital code of the currency',
      add_new_operation_to_the_contract: 'Add new operation to the contract',
      input_currency_symbol: 'Input currency symbol',
      new_password: 'Enter your new password',
      old_password: 'Enter your old password',
      code: 'Code',
      input_nickname: 'Input nickname',
      input_account_name: 'Account name',
      card_name: 'Card name',
      name: 'Name',
      otp_code: 'Input OTP-Code',
      input_card_number_or_choose_recipient: 'Input card number or choose recipient',
      input_card_number: 'Input card number',
      input_account_number_or_choose_recipient: 'Input account number or choose recipient',
      input_fraction: 'Input fraction',
    },
    select: {
      commission_type: 'Choose type',
      choose_account: 'Choose account',
      choose_card: 'Choose card',
      select_period: 'Select period',
      choose_bank_account: 'Choose bank account',
      recipient_coin: 'Choose recipient coin',
      select_invoice_template: 'Choose invoice template',
      choose_status: 'Choose status',
      payer_coin: 'Choose coin',
      choose_role: 'Choose role',
      select_role: 'Select role',
      select_language: 'Select language',
      select_type: 'Select type',
      select: 'Select',
      select_currency: 'Select currency code',
      choose_template: 'Choose template',
      select_option: 'Select option',
      choose_wallet: 'Choose wallet',
      choose_how_to_withdraw_the_funds: 'Choose how to withdraw the funds',
      choose_provider: 'Choose provider',
      choose_top_up_method: 'Choose how to add funds to your wallet',
      choose_cash_desk: 'Choose cash desk',
      select_date: 'Choose date',
      choose_wallet_method: 'Choose wallet method',
      choose_contact: 'Choose contact',
      choose_currency: 'Currency',
      no_available_currency_account: 'No available {currency} accounts',
    },

    confirm_title: 'Are you sure you want to delete it?',
    delete_title: 'Are you sure to delete {word}?',
    no_data: 'No data',

  },

  action: {
    change_contract: 'Change contract',
    back_to_sdk_finance_website: 'Back to SDK.finance website',
    sell: 'Sell',
    buy: 'Buy',
    block_account: 'Block account',
    edit_currency_exchange_list: 'Edit currency exchange list',
    click_to_upload: 'Click to upload',
    add_account: 'Add account',
    download_receipt: 'Download receipt',
    make_recurrent: 'Make recurrent',
    add_to_template: 'Add to template',
    dispute: 'Dispute',
    reject: 'Reject',
    continue: 'Continue',
    set_exchange_rate: 'Set exchange rate',
    pay: 'Pay',
    pay_for_invoice: 'Pay for an invoice',
    create_invoice: 'Create invoice',
    delete_invoice: 'Delete invoice',
    back: 'Back',
    sign_in: 'Sign In',
    sign_up: 'Sign up',
    logout: 'Log out',
    profile: 'Profile',
    add_new: 'Add new',
    create: 'Create',
    recover_pin: 'Recover PIN',
    update: 'Update',
    download_as_xls: 'Download as .XLS',
    upload_as_xls: 'Upload as .XLS',
    upload_file: 'Upload file',
    edit: 'Edit',
    delete: 'Delete',
    confirm: 'Confirm',
    approve: 'Approve',
    cancel: 'Cancel',
    view_details: 'View details',
    view_settings: 'View settings',
    clear_all_filters: 'Clear all filters',
    create_new_user: 'Create new user',
    redeem: 'Redeem',
    unban_user: 'Unban user',
    delete_user: 'Delete user',
    activate: 'Activate',
    deactivate: 'Deactivate',
    add_custom_contract: 'Add custom contract',
    add_new_gate_profile: 'Add new gate profile',
    add_provider: 'Add provider',
    add_provider_product: 'Add provider product',
    add_operation: 'Add operation',
    add_commission: 'Add commission',
    add_new_commission: 'Add new commission',
    add_limit_rule: 'Add limit rule',
    add_template: 'Add template',
    add_subscription: 'Add subscription',
    all_history: 'All history',
    repeat: 'Repeat',
    top_up: 'Top up',
    save_template: 'Save template',
    delete_template: 'Delete template',
    next: 'Next',
    exchange: 'Exchange',
    start: 'Start',
    stop: 'Stop',
    yes: 'Yes',
    no: 'No',
    submit: 'Submit',
    ok: 'Ok',
    decline: 'Decline',
    accept: 'Accept',
    recover: 'Recover',
    recovery_password: 'Recover password',
    forgot_password: 'Forgot password',
    resend: 'Resend',
    create_contact: 'Create contact',
    update_contact: 'Update contact',
    delete_contact: 'Delete contact',
    load_more: 'Load more',
    on_the_map: 'On the map',
    make_payment: 'Make payment',
    send_money: 'Send money',
    copy: 'Copy',
    share: 'Share',
    print: 'Print',
    add_card: 'Add card',
    close_account: 'Close account',
    save: 'Save',
    done: 'Done',
    create_new_contract: 'Create new contract',
    create_contract: 'Create contract',
    duplicate_from_existing: 'Duplicate from existing',
    details: 'Details',
    archive: 'Archive',
    transfer_money: 'Transfer money',
    edit_internal_operation_list: 'Edit internal operation list',
    update_internal_operation_list: 'Update internal operations list',
    add_internal_operation: 'Add internal operations',
    setup_providers: 'Setup providers',
    setup_gates: 'Setup gates',
    setup_currency_exchanges_rates: 'Setup currency exchanges rates',
    i_already_added_all_information: 'I already added all information',
    view_services_list: 'View services list',
    add_commissions_and_limits: 'Add commissions and limits',
    setup_contract_commissions: 'Setup contract commissions',
    save_commission: 'Save commissions',
    add_new_range: 'Add new range',
    save_as_template: 'Save as template',
    yes_delete: 'Yes, delete',
    no_cancel: 'No, cancel',
    add_user: 'Add user',
    resend_otp: 'Resend OTP',
    add_rule: 'Add rule',
    delete_rule: 'Delete rule',
    update_rule: 'Update rule',
    add_range: 'Add range',
    approve_profile: 'Approve profile',
    decline_profile: 'Decline profile',
    reject_passport: 'Reject passport',
    reject_identity_document: 'Reject identity document',
    approve_passport: 'Approve passport',
    approve_identity_document: 'Approve identity document',
    approve_tax_id: 'Approve Tax ID number',
    reject_tax_id: 'Reject Tax ID number',
    reset_kyc_status: 'Reset KYC status',
    view_unpaid_invoices: 'View unpaid invoices',
    create_top_up_request: 'Create top-up request',
    modify: 'Modify',
  },

  validation: {
    upload_image_with_restrictions: 'Image with max of {size} MB ({extensions})',
    does_not_match: 'Fields doesn\'t match',
    input_password_confirmation: 'Please input the password confirmation',
    is_required: 'Please fill in this field',
    min_allowed_length: 'Min allowed length: {value}',
    max_allowed_length: 'Max allowed length: {value}',
    allowed_value_range: 'Allowed value from {min} to {max}',
    invalid_integer_value: 'Invalid numeric value',
    invalid_integer_sign: 'Negative values is not allowed',
    invalid_value: 'Incorrect value',
    min_length: 'Value can’t be less than {min} symbols',
    max_length: 'Max length: {max}',
    please_input_email_or_phone: 'Please input email or phone',
    string_length: 'Length should be from {min} to {max} symbols',
    fixed_string_length: 'Length should be {number}',
    recipient_name: 'Please input recipient name',
    receiver_name: 'Please input receiver name',
    input_email: 'Please input email',
    invalid_email: 'Incorrect email',
    iban: 'Please input IBAN',
    name: 'Please input name',
    phone: 'Please input phone',
    invalid_phone: 'Incorrect phone',
    invalid_bic: 'Incorrect BIC',
    invalid_iban: 'Incorrect IBAN',
    invalid_email_or_phone: 'Incorrect email or phone',
    invalid_swift: 'Incorrect SWIFT',
    min_amount: 'Minimum amount is {value}',
    max_amount: 'Maximum amount is {value}',
    invalid_card_number: 'Incorrect card number',
    invalid_pin: 'Incorrect pin',
    positive_amount: 'Amount must be bigger then 0',
    input_phone: 'Please input phone',
    password: 'The password does not meet the requirements',
    confirm_password: 'Please confirm password',
    password_equal: 'Password is not equal',
    vat: 'Invalid VAT identification number',
    vat_length: 'Size must be between 3 and 30',
    min_percent: 'Minimum percent is {value}',
    max_percent: 'Maximum percent is {value}',
    invalid_address: 'Incorrect address',
    only_letters: 'Can include only letters',
    invalid_exp_date: 'Invalid expiration date',
  },

  navigation: {
    link: {
      bank_accounts: 'Bank accounts',
      exchange_rates: 'Exchange rates',
      withdrawal: 'Withdrawal',
      users: 'Users',
      points_of_sale: 'Points of sale',
      actions_of_users: 'Actions log',
      contracts: 'Contracts',
      contracts_old: 'Contracts old',
      currency: 'Currency',
      currencies: 'Currencies',
      issuers: 'Issuers',
      system_operations: 'System operations',
      variables: 'Variables',
      localization: 'I18n',
      gate_settings: 'Gate settings',
      transfers: 'Transfers',
      vouchers: 'Vouchers',
      transactions: 'Transactions',
      invoices: 'Invoices',
      identification: 'Identification',
      wallets: 'Wallets',
      accounts: 'Accounts',
      top_up: 'Top up via bank',
      contacts: 'Contacts',
      anomaly_analysis: 'Anomaly analysis',
      providers: 'Providers',
      payment_gateways: 'Payment gateways',
      internal_commissions: 'Internal commissions',
      payment_gateway: 'Payment gateway',
      internal_operations: 'Internal operations',
      currency_exchange: 'Currency exchange',
      merchant_contracts: 'Merchant contracts',
      individual_contracts: 'Individual contracts',
      pricing_plan: 'Pricing plan',
      customers: 'Customers',
      all_customers: 'All customers',
      pending_users: 'KYC pending users',
      block_list: 'Block list',
      cohort_analytics: 'Cohort analytics',
      customer_acquisition_cost: 'Customer acquisition cost',
      contract_service_commission: 'Contract service commission',
      provider_parameters: 'Provider parameters',
      create_a_new_contract: 'Create a new contract',
      services: 'Services',
      dashboard: 'Dashboard',
      internal_operations_list: 'Internal operations list',
      transactions_new: 'Transactions NEW',
      kyc_pending_customers: 'KYC pending customers',
    },
  },

  notification: {
    success: 'Operation success',
    error: 'Error',
    error_title: 'Error',
    success_title: 'Success',
    variable_is_update: 'Variable is updated',
    no_wallets_with_such_currency: 'You don’t have a wallet with such currency',
    no_exchange_rates_for_currencies: 'No exchange rates for this currencies',
    no_available_on_demo_mode: 'This functionality is unavailable on demo instance',
    copied: 'Copied!',
    copy_failed: 'Copy failed',
    not_found: 'Not found',
    otp_send: 'OTP-Code was sent to your email or phone',
    otp_incorrect: 'OTP-Code incorrect',
  },

  pages: {
    accounts: {
      title: 'All accounts',
      add_account: 'Add account',
    },
    profile_password: {
      one_uppercase_letter: 'One uppercase letter',
      one_lowercase_letter: 'One lowercase letter',
      one_digit: 'One digit',
      at_least_nine_characters: 'At least 8 characters',
      one_special_character: 'One special character such as # $ ! @ * & %',
    },
    bank_accounts: {
      title: 'Bank accounts',
      view_modal_title: 'Bank account',
    },
    maintenance: {
      patient: 'Patient you should be.',
      baby_yoda_is: 'Baby Yoda is ',
      maintaining: 'maintaining',
      our_system: 'our system.',
    },
    entrance: {
      title: 'Entrance',
      sign_in: 'Sign in',
      learn_more_about_sdk: 'Learn more about SDK.finance',
      payment_dashboard: 'Payment dashboards',
      api: 'API',
      documentation: 'Documentation',
      manual_guide: 'Manual guide',
      privacy_policy: 'Privacy Policy',
      terms_of_service: 'Terms of Service',
      cookie_policy: 'Cookie Policy',
      copyright: 'Copyright © 2019-{date}, TechFin UAB. All rights reserved.',
      dont_have_account: 'Don\'t have an account?',
      sign_in_manual: 'Or sign in with a login and password',
      recovery: {
        send_success_msg: 'An email has been sent. It contains a link,'
            + ' clicking on which you will be able to re-set the password.',
        confirm_success_msg: 'Your password has been successfully changed, for authorization go to the login page ',
        go_to_login: 'Go to the login',
        confirm_password_recovering: 'Confirm password recovering',
        password_recovering: 'Password recovering',
        return_to_the: 'Return to the',
      },
      registration: {
        sign_up: 'Sign up',
        need_merchant_account: 'Need merchant account?',
        need_individual_account: 'Need individual account?',
        legal_type: 'Legal type',
        individual: 'Individual',
        corporation: 'Corporation',
        have_otp: 'Have the code?',
        confirm_by_otp: 'Confirm by the code',
        go_to: 'Go to',
      },
      confirm_otp: {
        confirm_by_otp: 'Confirm by otp',
        return_to: 'Return to',
        resend: 'Resend',
        dont_have_code: 'Don\'t have a code?',
        successfully_registered: 'You are successfully registered. We sent the code to {login} to confirm your account.',
      },
    },
    users: {
      title: 'Users list',
      view_modal_create_title: 'Create a new user',
      individual: 'Individuals',
      corporate: 'Corporate',
      system_actors: 'System actors',
      user: 'user',
    },
    points_of_sale: {
      title: 'Points of sale',
      view_modal_create_title: 'Create point of sale',
      view_modal_edit_title: 'Edit point of sale',
      view_modal_title: 'Point of sale details',
      view_modal_delete_title: 'Are you sure want to delete point of sale?',
      information: 'Information',
      urls_options: 'URL\'s options',
      placeholders: {
        company_name: 'Company name',
        description: 'Point of sale description',
      },
    },
    currency: {
      title: 'Currencies',
      view_modal_title: 'Create new currency',
      modify_currency_title: 'Modify currency',
    },
    customer: {
      status: 'Status',
      action: {
        contact_customer: 'Contact customer',
        deactivate_customer: 'Deactivate customer',
        view_answer_list: 'View answer list',
        spectate_mode: 'Spectate mode',
        end_session: 'End session',
      },
      navigation: {
        link: {
          additional_information: 'Additional information',
          active_sessions: 'Active sessions',
          login_map: 'Login map',
          accounts: 'Accounts',
          contract_history: 'Contract history',
          credentials_history: 'Credentials history',
          user_information: 'User information',
          support_history: 'Support history',
          login_history: 'Login history',
          transactions: 'Transactions',
          disputes: 'Disputes',
          earnings: 'Earnings',
          kyc: 'KYC',
        },
      },
    },
    customers: {
      title: 'All customers',
      tabs: {
        individuals: 'Individuals',
        corporate: 'Corporate',
      },
    },
    issuers: {
      title: 'Issuers',
      view_modal_title: 'Create new issuer',
    },
    system_operations: {
      title: 'System operations',
      cash_collect: 'Cash collect',
      cash_input: 'Cash input',
      investment: 'Investment',
      gate_investment: 'Gate investment',
      request_success: 'Investment request was successfully sent',
      code_of_your_request: 'Code of your request',
    },
    pin_recovery: {
      title: 'PIN recovery',
    },
    variables: {
      title: 'Variables',
    },
    localization: {
      title: 'I18n',
      view_modal_create_title: 'Create i18n record',
      view_modal_update_title: 'Update i18n record',
    },
    gate_settings: {
      title: 'Gate settings',
    },
    transfers: {
      title: 'Transfers',
      view_modal_title: 'Transfer details',
      details_main_info: 'Main info',
      details_from: 'From',
      details_to: 'To',
    },
    vouchers: {
      title: 'Vouchers',
      view_modal_title: 'Voucher details',
      view_create_modal_title: 'Create voucher',
      view_created_modal_title: 'Voucher created!',
      view_created_affirm_subtitle: 'Make sure you saved PIN for voucher!',
      view_redeem_modal_title: 'Redeem voucher',
      view_redeem_activated_modal_title: 'Voucher activated!',
      details_main_info: 'Main info',
      details_from: 'From',
      details_to: 'To',
      voucher_number: 'Voucher number',
      voucher_pin: 'Voucher pin',
      pin: 'PIN',
      voucher_wallet: 'Wallet',
      voucher_amount: 'Amount',
      forgot_pin: 'Forgot PIN',
      view_amount_commission: 'The amount in view of commission',
      calculate_commission: 'Calculate commission',
      destination_coin: 'Destination coin number',
    },
    transactions: {
      title: 'Transactions list',
      view_modal_title: 'Transaction details',
      details: 'Details',
      balances: 'Balances',
      from_wallet: 'From wallet',
      to_wallet: 'To wallet',
      transfer: 'Transfer',
      sold_currency: 'Sold currency',
      bought_currency: 'Bought currency',
      information: 'Information',
      individuals: 'Individuals',
      merchants: 'Merchants',
      corporate: 'Corporate',
    },
    transaction_details: {
      title: 'Transaction details',
      transaction_date: 'Transaction date',
      category: 'Category',
      transaction_id: 'Transaction ID',
      status: 'Status',
      currency: 'Currency',
    },
    actions_of_users: {
      title: 'Actions log',
      view_modal_title: 'Action details',
      additional_details: 'Additional details',
    },
    user_profile: {
      title: 'User profile',
      user_contract: 'User Contract',
      navigation: {
        profile_and_business: 'Profile & Business',
        login_and_security: 'Login & Security',
        wallets: 'Wallets',
        accounts: 'Accounts',
        transactions: 'Transactions',
        actions_of_user: 'Actions log',
        kyc: 'KYC',
      },
      labels: {
        drop_file_or: 'Drop file here or',
        profile_information: 'Profile information',
        address: 'Address',
        business_information: 'Business information',
        type: 'Type',
        identification: 'Identification',
        legal: 'Legal',
        login_credentials: 'Login credentials',
        security: 'Security',
        transaction_notifications: 'Transaction notifications',
        authorization_notifications: 'Authorization notifications',
        dual_authentication: 'Dual authentication',
      },
    },
    user_profile_kyc: {
      all_documents_must_be_approved: 'All documents must be approved to complete KYC for the user.',
      kyc_status: 'KYC status',
      identity_document: 'Identity document',
      passport: 'Passport',
      tax_id_number: 'Tax ID number',
      status: 'Status',
      check_type: 'Check type',
      check_date: 'Check date',
      upload_date: 'Upload date',
      reset_kyc_status: 'Reset KYC status',
      reset_kyc_text: 'The user will need to upload the documents again to pass KYC verification.',
      confirm_reset_identification: 'Reset KYC status?',
      uploaded_after_registration: 'Uploaded after registration',
      image_not_uploaded: 'Image was\'t uploaded',
    },
    profile: {
      title: 'Profile settings',
      navigation: {
        profile_and_notification: 'Profile & notifications',
        bank_accounts: 'Bank accounts',
        password: 'Password',
      },
      labels: {
        type: 'Contract type',
      },
      add_bank_account: 'Add bank account',
      view_modal_add: 'Add bank account',
      view_modal_edit: 'Edit bank account',
      delete_bank_account: 'Delete bank account',
      confirm_delete_bank_account: 'Are you sure you want to delete this bank account?',
    },
    invoices: {
      invoice: 'Invoice',
      invoice_id: 'Invoice ID',
      name: 'Name',
      sender: 'Sender',
      transaction_amount: 'Transaction amount',
      title: 'Invoices list',
      invoice_name: 'Invoice name',
      recipient: 'Recipient',
      amount: 'Amount',
      view_modal_title: 'Invoice details',
      create_modal_title: 'Create invoice',
      invoice_created_modal_title: 'The invoice was successfully created!',
      invoice_payed_model_title: 'The invoice was successfully paid!',
      main_info: 'Main info',
      operation_details: 'Operation details',
      created_by_user: 'Created by user',
      payer_details: 'Payers details',
      payer_contact: 'Payer contact',
      commission: 'Commission',
      amount_without_commission: 'The amount without view of commission',
      pay_invoice_modal_title: 'Pay for the invoice',
      invoices_list: 'Invoices list',
      invoices_templates: 'Invoices templates',
      edit_invoice_template: 'Edit invoice template',
      confirm_delete_template: 'Are you sure you want to delete this template?',
      resend_invoice: 'Resend invoice',
      view_modal_delete_title: 'Delete pending invoice?',
      invoices: 'Invoices',
    },

    currency_exchange_contracts: {
      title: 'Currency exchanges',
    },

    providers: {
      tabs: {
        dashboard: 'Dashboard',
        list: 'Providers list',
      },
    },
    payment_gateways: {
      tabs: {
        dashboard: 'Dashboard',
        list: 'Payment gateways list',
      },
    },
    contracts: {
      title: 'Contracts list',
      navigation: {
        merchant: 'Merchant',
        individual: 'Individual',
        system_contracts: 'System contracts',
        provider_commission: 'Provider commission',
        system_commission: 'System commission',
        product_commission: 'Product commission',
        commissions: 'Commissions',
        limits: 'Limits',
      },
      modal: {
        add_new_provider: 'Add new provider',
        create_commission: 'Create commission',
        edit_commission: 'Edit commission',
        add_limit_settings: 'Add limit settings',
        edit_limit_settings: 'Edit limit settings',
        create_commission_profile: 'Create commission profile',
        edit_commission_profile: 'Edit commission profile',
        create_new_limit_profile: 'Create new limit profile',
        edit_limit_profile: 'Edit limit profile',
        add_custom_contract_for_merchants: 'Add custom contract for merchants',
        add_custom_contract_for_individuals: 'Add custom contract for individuals',
        add_new_operation_to_the_contract: 'Add new operation to the contract',
        edit_commission_rule: 'Edit commission rule',
        create_commission_rule: 'Create commission rule',
        add_new_provider_product: 'Add new provider product to the contract',
      },
      details: {
        commission: 'Commission',
        limits: 'Limits',
      },
      dashboard: {
        contracts: 'Contracts',
        total_earned: 'Total earned',
        active_customers: 'Active customers',
        customers: 'Сustomers',
        active_individuals: 'Active individuals',
        individuals: 'Individuals',
        active_merchants: 'Active merchants',
        merchants: 'Merchants',
        number_of_the_customers: 'Number of the customers',
        commissions_earned: 'Commissions earned',
        average_commission_per_client: 'Average commission per client',
        contracts_empty_msg: ' You haven\'t added any contracts yet. Please add contracts.',
        create_a_new_contract: 'Create a new contract',
        median_earning_per_client: 'Median earning per client',
        median_of_transactions_per_client: 'Median of transactions per client',
        percent_of_clients_using_this_last_month: 'Percent of clients using this last month',
        services: 'Services',
      },

      create: {
        welcome: 'Dear friend!',
        welcome_part_one: 'Before you start to create a new contract you should enter all details of providers, gates, '
            + 'internal commissions, currency exchanges.',
        welcome_part_two: 'For now, we haven’t got the needed information',
      },

      step_number: 'Step {n}',
      first_step: 'Step 1',
      main: 'Main',
      providers: 'Providers',
      payment_gateways: 'Payment gateways',
      payment_gates: 'Payment gates',
      internal_commissions: 'Internal commissions',
      currency_exchange: 'Currency exchange',
      approve: 'Approve',
      individual_contracts: 'Individual contracts',
      merchant_contracts: 'Merchant contracts',
      archived_individual_contracts: 'Archived individual contracts',
      archived_merchant_contracts: 'Archived merchant contracts',
      internal_operations: {
        title: 'Internal operations',
        edit_internal_operation_list: 'Edit internal operation list',
      },
    },
    wallets: {
      title: 'My accounts & balances',
      templates: 'Templates',
      subscription: 'Subscriptions & recurring payments',
      recent_operations: 'Recent operations',
      no_data_templates: 'No templates for this wallet',
      no_data_subscriptions: 'No subscription for this wallet',
      no_data_transfers: 'No transfers for this wallet',
      transfer_to_wallet: 'Transfer to wallet',
      transfer_to_account: 'Transfer to account',
      top_up_wallet: 'Top-up wallet',
      top_up_account: 'Top-up account',
      withdrawal: 'Withdrawal',
      currency_exchange: 'Currency exchange',
      add_new_wallet: 'Add new wallet',
      add_new_account: 'Add new account',
      create_wallet_modal: 'Add new wallet',
      confirm_delete_wallet: 'Are you sure you want to delete this wallet?',
      confirm_delete_template: 'Are you sure you want to delete this template?',
      confirm_delete_subscription: 'Are you sure you want to delete this subscription?',
      confirm_stop_subscription: 'Are you sure you want to stop this subscription?',
      recipient: 'Recipient',
      commission_amount: 'Commission amount',
      the_amount_in_view_of_commission: 'The amount in view of commission',
      sell: 'Sell',
      buy: 'Buy',
      incoming_commission: 'Incoming commission',
      outgoing_commission: 'Outgoing commission',
      sold: 'Sold',
      bought: 'Bought',
      in: 'in',
      from: 'from',
      inactive: 'inactive',
      ends_on: 'ends on',
      no_end_date: 'no end date',
      modal: {
        create_wallet: 'Add new wallet',
        create_top_up_modal_title: 'Top-up wallet',
        created_top_up_modal_title: 'Wallet balance has been top-up successfully!',
        transfer_to_wallet: 'Transfer to wallet',
        add_template: 'Add template',
        edit_template: 'Edit template',
        currency_exchange: 'Currency exchange',
        exchange_completed: 'Exchange completed!',
        create_subscription: 'Create subscription',
        withdrawal: 'Withdrawal',
      },
      methods: {
        provider: 'Provider',
        bank: 'Bank',
        cash_desk: 'Cash desk',
      },
      messages: {
        cash_desk_success: 'Application for withdrawal of {amount} {currency} from the wallet {serial} '
            + 'through the company\'s cash desk was created. '
            + 'Code of your request: {requestIdentifier}',
        provider_success: 'You have successfully withdrawn {amount} {currency} from the wallet {serial} '
            + 'throw {providerName}',
        bank_success: 'Application for withdrawal of {amount} {currency} from the wallet {serial} '
            + ' through the bank desk was created. Code of your request: {requestIdentifier}',
      },
    },
    withdrawal: {
      title: 'Withdrawal',
      navigation: {
        bank_operations: 'Bank operations',
        bank_withdrawal_requests: 'Bank withdrawal requests',
      },
      view_modal_title: 'Withdrawal details',
      details_main_info: 'Main info',
      details_bank_info: 'Bank info',
      details_client_wallet: 'Client wallet',
      details_issuer: 'Issuer',
      details_transactions: 'Transactions',
      view_confirm_accept: 'Are you sure to accept the request?',
      view_confirm_decline: 'Are you sure to decline the request?',
    },
    identification: {
      title: 'Identification',
    },
    top_up: {
      title: 'Top up via bank',
      navigation: {
        execute: 'Execute top up',
        requests: 'Top up requests list',
      },
      provider: 'Provider',
      bank: 'Bank',
      view_modal_title: 'Top up details',
      view_confirm_accept: 'Are you sure to accept the request?',
      view_confirm_decline: 'Are you sure to decline the request?',
      details_main_info: 'Main info',
      details_bank_info: 'Bank info',
      details_client_wallet: 'Client wallet',
      commission_amount: 'Commission amount',
      view_amount_commission: 'The amount in view of commission',
    },
    dashboards: {
      title: 'Payment Dashboards',
      daily_inconsistency_check_dashboard: 'Daily inconsistency check dashboard',
      settlement_reconciliation_dashboard: 'Settlement & Reconciliation dashboard',
      balance_dashboard: 'Balance dashboard',
      commissions_dashboard: 'Commissions dashboard',
      limits_dashboard: 'Limits dashboard',
    },
    currency_exchange: {
      title: 'Currency exchange',
      edit_modal_title: 'Edit rate',
      sell_currency: 'Sell currency',
      buy_currency: 'Buy currency',
      exchange_rate: 'Exchange rate',
      amount_to_receive: 'Amount to receive',
      amount_to_pay: 'Amount to pay',
    },
    contacts: {
      contacts_list: 'Contacts',
      unregistered_contacts: 'Unregistered contacts',
      create_contact: 'Create contact',
      update_contact: 'Update contact',
      confirm_delete_contact: 'Are you sure you want to delete this contact?',
    },
    user_dashboard: {
      modal: {
        exchange: {
          modal_title: 'Currency exchange',
          commission_details: 'You receive {to_currency_symbol}{receive_amount}, exchange rate 1 {from_currency} ='
            + ' {rate} {to_currency}',
          success_exchange_title: 'You’ve exchanged {from_currency_symbol}{from_amount}'
            + ' ({to_currency_symbol}{receive_amount}) to your “{account_name}”',
          success_exchange_subtitle: 'You can review your transfer anytime in your transactions list',
          success_exchange_default: 'Exchanged',
        },
      },

      total_balance: {
        welcome: 'Hello',
        title: 'Your total balance is',
      },

      transactions: {
        no_data: 'These are all transactions according to the specified parameters',
      },

      summary: {
        balance_structure_in: 'Balance structure in',
        subtitle: 'Summary',
        spending_by_categories: 'Spending by categories',
      },

      cards: {
        title: 'All cards',
        create_modal_title: 'Add card',
      },

      account: {
        close_message: 'This action is  irreversible. Block the account if you want to temporarily disable it.',
        close_question: 'Are you sure that want to close your "{accountName}"?',
        close_question_info: 'You will not be able to undo this action.',
        close_success: 'You have successfully close your "{accountName}"',
        close_success_info: 'You can review your transfer anytime in your Transactions list',
      },

      security: {
        two_factor_disabled: 'Two-factor authentication disabled',
        two_factor_enabled: 'Two-factor authentication enabled',
        two_factor_authentication: 'Two-factor authentication via SMS',
        transaction_notifications: 'Transaction notifications',
        two_factor_authentication_info: 'Please verify the phone number in Login details to enable two factor authentication.',
        transaction_notification_info: 'These settings only affect outgoing transactions',
      },

      profile: {
        title: 'Profile',
      },

      links: {
        password: 'Password',
        accounts: 'Accounts',
        transactions: 'Transactions',
        summary: 'Summary',
        loans: 'Loans',
        investment: 'Investment',
        cards: 'Cards',
        details: 'Account details',
        settings: 'Settings',
        card_details: 'Card details',
        personal_information: 'Personal information',
        documents: 'Documents',
        notifications: 'Notifications',
        password_and_security: 'Password & security',
        login_details: 'Login details',
        security: 'Security',
      },

      make_payment: 'Make payment',
      new_payment: 'New payment',

      payments: {
        top_up_title: 'Top up',
        top_up_subtitle: 'How do you want to top up your account?',
        debit_card: 'Debit card',
        regular_bank_transfer: 'Regular bank transfer',
        make_payment_title: 'Make payment',
        payment_to_card: 'Payment to card',
        transfer_to_card: 'Transfer to card',
        payment_to_account: 'Payment to account',
        transfer_to_account: 'Transfer to account',
        mobile_top_up: 'Mobile top-up',
        other_payments: 'Other payments (utility bills, internet, etc.)',
        currency_exchange_title: 'Currency exchange',
        currency_exchange_subtitle: 'Do you want to sell or buy currency?',
        sell: 'Sell',
        buy: 'Buy',
        top_up_via_debit_card: 'Top up via debit card',
        card_details: 'Card details',
        amount: 'Amount',
        failed_top_up: 'Failed',
        failed_top_up_subtitle: 'Received a Failed response from MasterCard',
        success_top_up: 'You have successfully topped up your "{accountName}" account with {currency}{amount}',
        success_top_up_subtitle: 'You can review your transfer anytime  in your Transactions list',
        top_up_via_bank: 'Top up via bank transfer',
        select_the_account_you_want_to_top_up: 'Select the account you want to top up',
        use_these_data_to_top_up_your_account: 'Use these data to top up your account',
        enter_card_details: 'Enter card details',
        select_account: 'Select the account you want to top up and enter the amount of topping up',
        payment_card_title: 'Payment to card',
        transfer_card_title: 'Transfer to card',
        payment_account_title: 'Payment to account',
        transfer_account_title: 'Transfer to account',
        save_template_title: 'Save as template',
        payment_success_title: 'Payment success',
        payment_success_info: 'You can review your transfer anytime  in your Transactions list',
        template_delete_title: 'Are you sure that want to delete this template?',
        template_delete_info: 'You will not be able to undo this action.',
        bank_transfer_title: 'Bank transfer (utility bills, internet, etc.)',
        mobile_top_up_title: 'Mobile top-up',
      },
    },

    kyc: {
      title: 'KYC pending customers',
      counter_title: 'Need an approval',
    },
  },

  table: {
    empty_text: 'No data',
    unverified: 'Unverified',
    label: {
      last_session: 'Last session',
      last_session_time: 'Last session time',
      operations: 'Operation',
      session: 'Session',
      duration: 'Duration',
      account_number: 'Account number',
      device: 'Device',
      location: 'Location',
      start_time: 'Start time',
      amount_from: 'Amount from',
      amount_to: 'Amount to',
      commission_type: 'Commission type',
      fixed: 'Fixed',
      percent: 'Percent',
      reason: 'Reason',
      source: 'Source',
      transaction_id: 'Transaction ID',
      percent_of_last_month: '% of using this provider last month',
      earned_last_month: 'Earned last month',
      median_transaction: 'Median transactions last month',
      median_earning: 'Median earning last month',
      endDate: 'End date',
      startDate: 'Start date',
      available_amount: 'Available amount',
      cohort: 'Cohort',
      contract: 'Contract',
      last_login: 'Last login',
      last_transaction: 'Last transaction',
      percent_of_using_this_gate_last_month: '% of using this gate last month',
      type_of_the_internal_commission: 'Type of the internal commission',
      median_transactions_last_month: 'Median transactions last month',
      median_earning_last_month: 'Median earning last month',
      sell_amount: 'Sell amount',
      rate: 'Buy',
      contacts: 'Contacts',
      identification: 'Identification',
      role: 'Role',
      recipient_name: 'Recipient name',
      account_details: 'Account details',
      bank_info: 'Bank info',
      name: 'Name',
      code: 'Code',
      digital_code: 'Digital code',
      symbol: 'Symbol',
      description: 'Description',
      website: 'Website',
      id: 'ID',
      currency_name: 'Currency name',
      active: 'Active',
      inactive: 'Inactive',
      sn_prefix: 'SN prefix',
      type: 'Type',
      value: 'Value',
      action: 'Action',
      actions: 'Actions',
      key: 'Key',
      ru: 'Russian',
      uk: 'Ukrainian',
      en: 'English',
      supported_transaction_types: 'Supported transaction types',
      provider_name: 'Provider name',
      transaction_type: 'Transaction type',
      created_at: 'Created at',
      amount: 'Amount',
      status: 'Status',
      activatedAt: 'Activated at',
      serial: 'Serial',
      commission: 'Commission',
      direction: 'Direction',
      transactions_id: 'Transactions id',
      from: 'From',
      to: 'To',
      performed_date: 'Performed date',
      user: 'User',
      comment: 'Comment',
      ids: 'IDs',
      user_agent: 'User agent',
      action_id: 'Action ID',
      session_id: 'Session ID',
      user_id: 'User ID',
      host_ip: 'Host IP',
      owner_name: 'Owner’s name',
      email: 'Email',
      phone_number: 'Phone number',
      ban_expiry_day: 'Ban expiry day',
      provider: 'Provider',
      payment_gateways: 'Payment gateway',
      currency: 'Currency',
      top_up_commission: 'Top-up commission',
      redeem_commission: 'Redeem commission',
      limits: 'Limits',
      total: 'Total',
      and: 'and',
      configured: 'Configured',
      no_limits: 'No limits',
      provider_commission: 'Provider commission',
      total_commission: 'Total commission',
      product: 'Product',
      qualifier: 'Qualifier',
      time_unit: 'Time unit',
      operation: 'Operation',
      date: 'Date',
      operation_type: 'Operation type',
      service: 'Service',
      product_code: 'Product code',
      quantity_of_goods: 'Quantity of goods',
      receiver: 'Receiver',
      expired_at: 'Expired at',
      expires_at: 'Expires at',
      template_name: 'Template name',
      recipient_wallet: 'Recipient wallet',
      payer_contact: 'Payer contact',
      nickname: 'Nickname',
      full_name: 'Full name',
      iban: 'Iban',
      bank_name: 'Bank name',
      payment_reason: 'Payment reason',
      today: 'Today',
      yesterday: 'Yesterday',
      number_of_users: 'Number of users',
      avr_incom_per_user_per_month: 'Avr incom per user per month',
      start_date: 'Start date',
      income: 'Income',
      category: 'Category',
      part_of_all_the_spending: 'Part of all the spending',
      provider_status: 'Provider status',
      services: 'Services',
      added_commissions: 'Added commissions',
      service_status: 'Service status',
      limit_type: 'Limit type',
      period: 'Period',
      min_commission_amount: 'Min commision amount',
      max_commission_amount: 'Max commision amount',
      type_of_the_internal_operations: 'Type of the internal operations',
      internal_operation_name: 'Internal operations name',
      payment_way: 'Payment way',
      person_type: 'Person type',
      owner_s_name: 'Owner’s name',
      registration_date: 'Registration date',
      commission_sender: 'Commissions sender',
      commission_recipient: 'Commissions recipient',
      destination: 'Destination',
      provider_fixed: 'Provider - Fixed',
      provider_percent: 'Provider - Percent',
      total_fixed: 'Total - Fixed',
      total_percent: 'Total - Percent',
      fraction: 'Fraction',
    },
    pagination: {
      next: 'Next',
      prev: 'Prev',
    },
  },

  filter: {
    iban: 'Iban',
    bank_name: 'Bank name',
    swift: 'Swift',
    payment_identifier: 'Payment identifier',
    activated: 'Activated',
    not_activated: 'Not activated',
    text: 'Text',
    email: 'Email',
    phone: 'Phone',
    total_price: 'Total price',
    creator_id: 'Creator id',
    id: 'Id',
    name_email_phone: 'Name/email/phone',
    role: 'Role',
    user_banned: 'User banned',
    user_ban_status: 'User ban-status',
    user_role: 'User role',
    account_number: 'Account number',
    tid: 'TID',
    national_id: 'National ID',
    organization_id: 'Organization ID',
    search: 'Search',
    key: 'Key',
    value: 'Value',
    active_filters: 'Active filters',
    status: 'Status',
    currency_code: 'Currency code',
    created_at: 'Created at',
    activated_at: 'Activated at',
    date_from: 'Date from',
    date_to: 'Date to',
    wallet_serial: 'Wallet serial',
    serial: 'Serial',
    wallet_number: 'Wallet number',
    transaction_id: 'Transaction ID',
    issuer_id: 'Issuer ID',
    request_identifier: 'Request identifier',
    types: 'Types',
    id_of_action: 'Action ID',
    id_of_user: 'User ID',
    date: 'Date',
    ip_of_user: 'User IP',
    host: 'Host',
    contract_type: 'Contract type',
    nickname: 'Nickname',
    phone_number: 'Phone number',
    select_period: 'Select period',
    transactions: 'Transactions',
    select: 'Select',
    contract_roles: 'Contract roles',
    period: 'Period',
    compare: 'Compare',
    application_status: 'Application status',
    statuses: 'statuses',
    issuer: 'Issuer',
    currency: 'Currency',
  },

  form: {
    label: {
      person_type: 'Person type',
      change_contract_to: 'Change Contract to',
      source_currency: 'Source currency',
      destination_currency: 'Destination currency',
      identity_document: 'Identity document',
      old_password: 'Old password',
      sell_amount: 'Sell amount',
      buy_amount: 'Buy amount',
      otp_code: 'Input OTP-code',
      or_input_tax_id: 'Or input Tax ID number manually',
      input_tx_id_manually: 'Input Tax ID manually',
      input_tax_id: 'Input Tax ID number',
      recipient: 'Recipient',
      sold_amount: 'Sold amount',
      bought_amount: 'Bought amount',
      sender: 'Sender',
      gross_amount: 'Gross amount',
      recipient_fee: 'Recipient fee',
      pos_company_name: 'POS company name',
      you_pay: 'You pay',
      from_account: 'From account',
      to_account: 'To account',
      passport: 'Passport',
      tax_id_number: 'Tax ID number',
      browse: 'Browse',
      categoryName: 'Category name',
      categoryCode: 'Category code',
      my_bank_accounts_list: 'My bank account list',
      autofill_from_bank_account: 'Autofill from my bank account list',
      rate: 'For selling 1 {inCurrency} you can get the following {outCurrency} amount',
      product: 'Product',
      product_price: 'Product price',
      count: 'Count',
      coin: 'Coin',
      product_code: 'Product code',
      recipient_coin: 'Recipient coin',
      payer_contact: 'Payer contact',
      invoice_name: 'Invoice name',
      cash_amount: 'Cash amount',
      performed_at: 'Performed at',
      user_contact: 'User contact',
      language: 'Language',
      account_number: 'Account number',
      iban: 'IBAN',
      recipient_full_name: 'Recipient full name',
      swift: 'SWIFT',
      bank_name: 'Bank name',
      bic: 'BIC',
      bank_address: 'Bank address',
      bank_country: 'Bank country',
      password: 'Password',
      bank_city: 'Bank city',
      current: 'Current',
      new_password: 'New password',
      confirm_password: 'Confirm new password',
      website: 'Website',
      server_url: 'Server URL',
      result_url: 'Result URL',
      trust_certificates: 'Trust all certificates',
      created_at: 'Created at',
      updated_at: 'Updated at',
      activated_at: 'Activated at',
      amount: 'Amount',
      description: 'Description',
      status: 'Status',
      request_id: 'Request ID',
      request_status: 'Request status',
      transaction_id: 'Transaction ID',
      transaction_type: 'Transaction type',
      wallet_serial: 'Wallet serial',
      organization_id: 'Organization ID',
      technical: 'Technical',
      wallet_type: 'Wallet type',
      issuer_id: 'Issuer ID',
      serial_number: 'Serial number',
      currency: 'Currency',
      net_amount: 'Net amount',
      commission: 'Commission',
      wallet_number: 'Wallet number',
      organization_name: 'Organization name',
      user_on_entity_id: 'User/Entity ID',
      role: 'Role',
      type: 'Type',
      action: 'Action',
      name: 'Name',
      link_to_existing_organization: 'Link to existing organization',
      login: 'Login',
      system_role: 'System role',
      legal_type: 'Legal type',
      middle_name: 'Middle name',
      surname: 'Surname',
      code: 'Code',
      symbol: 'Symbol',
      digital_code: 'Digital code',
      sn_prefix: 'SN prefix',
      order_number: 'Order number',
      order_quote: 'Order quote',
      serial_number_voucher: 'Serial number of the voucher',
      name_value: 'Name/value',
      key: 'Key',
      country: 'Country',
      city: 'City',
      street: 'Street',
      house_number: 'House number',
      zip_code: 'Zip code',
      company_name: 'Company name',
      business_type: 'Business type',
      vat: 'VAT identification number',
      first_name: 'First name',
      last_name: 'Last name',
      email: 'Email',
      phone: 'Phone',
      enable: 'Enable',
      provider: 'Provider',
      active: 'Active',
      total_commission: 'Total commission',
      provider_commission: 'Provider commission',
      fixed_value: 'Fixed value',
      percent_value: 'Percent value',
      value: 'Value',
      time_unit: 'Time unit',
      qualifier: 'Qualifier',
      direction: 'Direction',
      operation_flow: 'Operation flow',
      id: 'Id',
      expired_at: 'Expired at',
      total_price: 'Total price',
      vat_applied: 'Vat applied',
      merchant_name: 'Merchant name',
      payment_code: 'Payment code',
      status_identification: 'Status identification',
      expires_at: 'Expires at',
      message: 'Message',
      wallet_name: 'Wallet name',
      template_name: 'Template name',
      from_wallet: 'From Wallet',
      recipient_wallet: 'Recipient wallet',
      save_as_template: 'Save as template',
      sell: 'Sell',
      buy: 'Buy',
      contract: 'Contract',
      subscription_name: 'Subscription name',
      template: 'Template',
      expiration_type: 'Expiration type',
      start_date: 'Start date',
      subscription_type: 'Subscription type',
      transfer_frequency: 'Transfer frequency',
      expiration_amount: 'Expiration amount',
      end_date: 'End date',
      wallet: 'Wallet',
      withdrawal_method: 'Withdrawal method',
      cash_desk_options: 'Cash desk options',
      receiver_name: 'Receiver name',
      provider_options: 'Provider options',
      top_up_method: 'Top-up method',
      recipient_account: 'Recipient account',
      method: 'Method',
      cash_desk: 'Cash desk',
      full_name: 'Full name',
      nickname: 'Nickname',
      phone_number: 'Phone number',
      wallet_methods: 'Wallet methods',
      from_my_contacts: 'From my contacts',
      contacts: 'Contacts',
      email_or_phone: 'Email or phone',
      account_name: 'Account name',
      account_serial: 'Account serial',
      beneficiary_address: 'Beneficiary address',
      beneficiary: 'Beneficiary',
      holder: 'Holder',
      number: 'Number',
      expire_date: 'Expire date',
      cvv: 'CVV',
      payment_system: 'Payment system',
      card_name: 'Card name',
      block_card: 'Block card',
      block_account: 'Block account',
      account_to_transfer: 'Choose an account to transfer the balance',
      category_id: 'Category',
      email_address: 'Email address',
      date_of_birth: 'Date of birth',
      choose_account_or_recipient: 'Choose account or recipient',
      recipient_gets: 'Recipient gets',
      contact_name: 'Contact name',
      contact_for: 'Contact for',
      limits: 'Limits',
      account: 'Account',
      transaction_amount: 'Transaction amount',
      card_number: 'Card number',
      expiry_date: 'Expiry date',
      two_factor_auth_disabled: 'Two factor authentication disabled',
      two_factor_auth_enabled: 'Two factor authentication enabled',
      send_email: 'Send Email',
      send_sms: 'Send SMS',
      from_card: 'From card',
      to_card: 'To card',
      payment_amount: 'Payment amount',
      transfer_amount: 'Transfer amount',
      fee: 'Fee',
      saved_templates: 'Saved templates',
      company_or_iban: 'Company or IBAN',
      name_or_phone_number: 'Name or phone number',
      my_phone_numbers: 'My phone numbers',
      inactive: 'Inactive',
      sender_fee: 'Sender fee',
      invoice_id: 'Invoice ID',
      choose_account: 'Choose account',
      commission_direction: 'Commission direction',
      snPrefix: 'SN Prefix',
      fraction: 'Fraction',
    },
  },

  entity: {
    date_range: {
      all_time: 'All time',
      this_week: 'This week',
      this_month: 'This month',
      custom_period: 'Custom period',
      for_all_time: 'for all time',
      for_this_week: 'for this week',
      for_this_month: 'for this month',
      for_custom_period: 'for custom period',
    },

    identification_status: {
      approved: 'Approved',
      declined: 'Declined',
      pending: 'Need approval',
      none: 'Waiting for the customer’s documents',
    },

    invoice: {
      status: {
        paid: 'paid',
        pending: 'pending',
        declined: 'declined',
        expired: 'expired',
        approved: 'approved',
        initiated: 'initiated',
        all: 'all',
      },
    },

    transaction: {
      status: {
        pending: 'Pending',
        declined: 'Declined',
        processed: 'Processed',
        error: 'Error',
        rejected: 'Rejected',
        approved: 'Approved',
      },
      type: {
        client_transaction_transfer: 'Transfer',
        client_transaction_split: 'Split',
        client_transaction_merge: 'Merge',
        client_transaction_issue: 'Account creation',
        client_transaction_balance: 'Balance',
        client_transaction_redeem: 'Transaction redeem',
        client_charge_prepaid: 'Voucher activation',
        client_create_prepaid: 'Voucher creation',
        merchant_payment: 'Merchant payment',
        merchant_invoice: 'Invoice',
        gate_charge: 'Top up via provider',
        gate_redeem: 'Withdrawal via provider',
        gate_purchase: 'Purchase via provider',
        gate_purchase_via_gate: 'Gate purchase via gate',
        gate_card_refund: 'Gate card refund',
        gate_card_verification: 'Gate card verification',
        exchange_transaction: 'Currency exchange',
        cash_desk_redeem: 'Withdrawal via cash desk',
        cash_desk_charge: 'Top up via cash desk',
        payroll_charge: 'Payroll charge',
        payroll_charge_sub: 'Payroll charge sub',
        reserve_charge: 'Reserve charge',
        reserve_redeem: 'Reserve redeem',
        cash_investment: 'Provider/cash desk account top up',
        cash_collect: 'Cash desk collect',
        cash_input: 'Cash desk input',
        contract_transit: 'Contract change fee',
        merchant_cashback: 'Merchant cashback',
        deposit_topup: 'Deposit topup',
        deposit_profit_payment: 'Deposit profit payment',
        deposit_payout: 'Deposit payout',
        deposit_capitalization: 'Deposit capitalization',
        deposit_accruing: 'Deposit accruing',
        credit_issue: 'Credit issue',
        credit_payment: 'Credit payment',
        bank_topup: 'Top up via bank',
        bank_redeem: 'Withdrawal via bank',
        withdraw_exchange_transaction: 'Withdraw exchange',
        undefined: 'Unknown',
      },

    },

    user: {
      banned: 'Banned',
      unbanned: 'Unbanned',
    },

    role: {
      administrator: 'Administrator',
      cfo: 'CFO',
      individual: 'Individual',
      merchant: 'Merchant',
      compliance_manager: 'Compliance manager',
      compliance_specialist: 'Compliance specialist',
      customer_support: 'Customer support',
      antifraud_specialist: 'Antifraud specialist',
      accountant: 'Accountant',
      cashier: 'Cashier',
      payroll_manager: 'Payroll manager',
    },

    legal: {
      individual: 'Individual',
      corporation: 'Corporation',
    },

    business_type: {
      eshop: 'Internet shop',
      reseller_of_goods: 'Reseller of goods',
      service_providers: 'Service provider',
      mobile_operator: 'Mobile operator',
      storage_providers: 'Storage provider',
      high_risk: 'High risk',
      other: 'Other',
    },

    invoices: {
      status: {
        initiated: 'Initiated',
        pending: 'Pending',
        approved: 'Approved',
        paid: 'Paid',
        declined: 'Declined',
        expired: 'Expired',
      },
    },

    contracts: {
      types: {
        base: 'Base',
        standard: 'Standard',
        gold: 'Gold',
        vip: 'VIP',
      },
    },

    time_unit: {
      per_transaction: 'Per transaction',
      per_day: 'Per day',
      per_week: 'Per week',
      per_month: 'Per month',
      per_quarter: 'Per quarter',
      per_year: 'Per year',
      per_life: 'Per life',
    },

    commission_types: {
      zero: 'Zero',
      percent: 'Percent',
      fixed: 'Fixed',
      greater_of_fixed_or_percent: 'Greater of fixed or percent',
      lesser_of_fixed_or_percent: 'Lesser of fixed or percent',
      fixed_and_percent: 'Fixed and percent',
    },

    qualifier: {
      amount: 'Amount',
      quantity: 'Quantity',
    },

    transaction_types: {
      transfer: 'Transfer',
      split: 'Split',
      merge: 'Merge',
      issue: 'Issue',
      balance: 'Balance',
      redeem: 'Redeem',
      commission: 'Commission',
      authorization: 'Authorization',
      commission_authorization: 'Commission authorization',
      capture: 'Capture',
      commission_capture: 'Commission capture',
      reversal: 'Reversal',
      commission_reversal: 'Commission reversal',
      hold: 'Hold',
    },

    coin_types: {
      regular_commission: 'Regular commission',
      business_commission: 'Business commission',
      regular_gate_commission: 'Regular gate commission',
      business_gate_commission: 'Business gate commission',
      gate: 'Gate',
      reserve: 'Reserve',
      prepaid: 'Prepaid',
      credit: 'Credit',
      deposit: 'Deposit',
      deposit_accrued: 'Deposit accrued',
      client: 'Client',
      cash: 'Cash',
      cashback_merchant: 'Cashback merchant',
      cashback_payer: 'Cashback payer',
      debt: 'Debt',
      merchant_operational: 'Merchant operational',
      merchant_reserve: 'Merchant reserve',
      vat: 'Vat',
      system_vat: 'System vat',
      provider_vat: 'Provider vat',
      internal_client: 'Internal client',
      merchant_reserve_debt: 'Merchant reserve debt',
      payment_in_advance: 'Payment in advance',
      charge_back: 'Charge back',
    },

    commission_direction: {
      IN: 'In',
      OUT: 'Out',
      SHARED: 'Shared',
    },

    money_flow: {
      in: 'Money in',
      out: 'Money out',
      most_expensive_category: 'The most expensive category',
    },

    subscription_types: {
      default: {
        daily: 'Daily',
        weekly: 'Weekly',
        monthly: 'Monthly',
        annually: 'Annually',
      },
      card: {
        daily: 'per day',
        weekly: 'per week',
        monthly: 'per month',
        annually: 'per year',
      },
    },

    expiration_types: {
      date: 'Date',
      infinity: 'Infinity',
      count: 'Count',
      amount: 'Amount',
    },

    error: {
      UNKNOWN: 'Unknown server error',
      FILE_UPLOAD: 'Error occurred during uploading file',
    },

    badge_status: {
      active: 'Active',
      inactive: 'Inactive',
      authorized: 'Authorized',
      banned: 'Banned',
      pending: 'Pending',
      new: 'New',
      rejected: 'Rejected',
      done: 'Done',
      paid: 'Paid',
      declined: 'Declined',
    },
  },

  event_notification: {
    you_have_unpaid_invoices: 'You have unpaid invoices',
  },

  other: {
    spent: 'spent',
    left: 'left',
    transactions: 'transaction | transactions',
    no_fee: 'No fee',
    empty_list: 'Empty list',
    delete_template: 'Delete template?',
  },
};

export default en;
