import { TLanguageCode } from '@/constants/languages';
import api from '@/services/api';
import { IApiResponse } from '@/types/interfaces';

export const I18nRequests = {
  getTranslations(locale: TLanguageCode = 'en', type: 'json' | 'property' = 'json'): Promise<IApiResponse<Record<string, string>>> {
    return api.get(`/i18n/export/${locale}.${type}`, {
      responseType: 'json',
    });
  },
};
