import api from '@/services/api';
import { ICreateCurrency, ICurrencyResponse, IUpdateCurrency } from '@/services/requests/currencies/Currency.types';
import { IApiResponse } from '@/types/interfaces';

export const CurrencyRequests = {

  getCurrencies(): Promise<IApiResponse<ICurrencyResponse>> {
    return api.get('/currencies');
  },

  create(options: ICreateCurrency): Promise<IApiResponse<any>> {
    return api.post('/currencies', options);
  },

  update(currencyId: string, data: IUpdateCurrency): Promise<IApiResponse<any>> {
    return api.patch(`/currencies/${currencyId}`, data);
  },
};
